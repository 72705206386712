<template>
  <div>
    <!-- header section starts -->
    <header class="header">
      <a href="#" class="logo"
        >ASK <span title="Gras Savoye Bénin">GSB</span>
      </a>
      <nav class="navbar">
        <router-link to="/">Accueil</router-link>
      </nav>
    </header>

    <!-- header section ends -->

    <!-- politiques section starts  -->

    <section class="politiques" id="politiques">
      <h1 class="heading">Législation sur la protection des données ASK GSB</h1>

      <div class="row">
        <div class="content">
          <!-- <h3>Courtier leader du marché béninois!</h3> -->
          <h3>
            Collecte et utilisation d’informations a caractère personnel :
          </h3>
          <p>
            Vous confirmez que vous avez l'autorité pour agir au nom de vos
            ayants droit en ce qui concerne toutes les informations à caractère
            personnel que vous nous fournissez, que vous consentez également à
            la collecte des empreintes digitales, au traitement, à la
            divulgation, à l'utilisation et à la conservation des informations
            vous concernant et/ou à propos de vos ayants droits.
          </p>
          <p>
            Les informations collectées sont destinées à la gestion de vos
            garanties, que ce soit dans le bulletin d'adhésion, tout formulaire
            de demande de remboursement, tout formulaire d'entente préalable
            et/ou tout autre document complémentaire ou information que nous
            pourrions recueillir en lien avec un produit ou service que nous
            proposons.
          </p>
          <p>
            ASK Gras Savoye Bénin, est le responsable de la protection de ces
            données.
          </p>
          <h3>Utilisation :</h3>
          <p>
            Les informations personnelles peuvent être utilisées dans le but de
            gérer l'assurance (y compris la souscription, le traitement des
            demandes de remboursement et la prévention des fraudes).
          </p>
          <p>
            Nous pouvons avoir recours à des organismes ou personnes tiers pour
            traiter des données en notre nom.
          </p>
          <p>
            Ce traitement est soumis à
            des restrictions contractuelles en ce qui concerne la
            confidentialité et la sécurité, en accord avec les exigences en
            matière de protection des données.
          </p>
          <h3>Données sensibles :</h3>
          <p>
            Nous devons recueillir des données sensibles vous concernant (telles
            que des informations médicales), afin d'évaluer les termes de
            l'assurance que nous émettons/fixons et/ou pour gérer les demandes
            de remboursement.
          </p>
          <p>
            Elles pourront être utilisées à des fins statistiques ou pour vous
            proposer des offres commerciales émanant de ASK Gras Savoye Bénin et
            de ses partenaires dont la liste vous sera transmise sur simple
            demande, aux fins de vous informer de nos offres de produits et
            services.
          </p>
          <p>
            Nous sommes tenus de conserver vos données pendant les six années
            suivant la fin de la période d'assurance.
          </p>
          <p>
            Nous ne conserverons pas vos données plus longtemps que nécessaire
            et uniquement pour les finalités pour lesquelles nous les avons
            obtenues.
          </p>
          <p>
            Conformément à la loi 2017-20 du 20 Avril 2018 portant code du
            numérique en République du Bénin, vous disposez d'un droit d'accès,
            de rectification et d'opposition, relatif aux informations vous
            concernant.
          </p>
          <p>
            Vous pouvez exercer ce droit en justifiant de votre identité à
            l'adresse suivante
            <b
              >ASK Gras Savoye Bénin, Quartier Djominhoutin, Boulevard Marina 01
              BP 6901 Cotonou</b
            >
            ou par courrier à : <b>administration@bj.askgs.africa</b>
          </p>
        </div>
      </div>
    </section>

    <!-- politiques section ends -->

    <!-- contact section starts  -->

    <section class="contact" id="contact">
      <h1 class="heading">Approbation</h1>

      <div class="row">
        <form @submit.prevent="submit">
          <h3>Formulaire d'approbation</h3>
          <div class="contact-double-field">
            <div class="content-field">
              <input
                v-model="approbation_form.lastname"
                @blur="v$.approbation_form.lastname.$touch"
                type="text"
                placeholder="Nom *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.lastname.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="content-field">
              <input
                @blur="v$.approbation_form.firstname.$touch"
                v-model="approbation_form.firstname"
                type="text"
                placeholder="Prénoms *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.firstname.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="contact-double-field">
            <div class="content-field">
              <input
                @blur="v$.approbation_form.company.$touch"
                v-model="approbation_form.company"
                type="text"
                placeholder="Societé *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.company.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="content-field">
              <input
                @blur="v$.approbation_form.title.$touch"
                v-model="approbation_form.title"
                type="text"
                placeholder="Fonction *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.title.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="contact-double-field">
            <div class="content-field">
              <input
                @blur="v$.approbation_form.email.$touch"
                v-model="approbation_form.email"
                type="email"
                placeholder="Email *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.email.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="content-field">
              <input
                @blur="v$.approbation_form.telephone.$touch"
                v-model="approbation_form.telephone"
                type="number"
                placeholder="Téléphone *"
                class="box"
              />
              <div
                class="input-errors"
                v-for="error of v$.approbation_form.telephone.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <textarea
            v-model="approbation_form.message"
            name=""
            placeholder="Message"
            class="box"
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <span class="information">
            * En cliquant sur le bouton Soumettre ci-dessous, vous reconnaissez
            avoir pris connaissance du contenu sur
            <a href="#politiques"
              >la législation de la protection des données ASK GSB</a
            >
            telle que libellée ci-dessus
          </span>
          <vue-recaptcha
            v-if="!v$.approbation_form.$error"
            @verify="verifyMethod"
            sitekey="6LcU804iAAAAAOgqAy3nA6XfGiEyqfSIex-Mnxhb"
          ></vue-recaptcha>
          <input
            :disabled="!captcha_verified || v$.approbation_form.$error"
            type="submit"
            value="Soumettre"
            class="btn"
          />
        </form>
      </div>
    </section>

    <!-- contact section ends -->

    <!-- footer section starts  -->

    <section class="footer">
      <div class="links">
        <a class="btn" href="/">Accueil</a>
      </div>

      <div class="credit">
        ASK <span> Gras Savoye Bénin, </span>
        <!-- | tous droits reservés! | -->
        <!-- <a href="#">Mentions Légales</a> | -->
        <!-- <a href="#">politiques de confidentialité</a> -->
        <router-link to="/politiques"
          >politiques de confidentialité</router-link
        >
      </div>
    </section>

    <!-- footer section ends -->
  </div>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import axios from "axios";

export default {
  components: { VueRecaptcha },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      captcha_verified: false,
      approbation_form: {
        lastname: "",
        firstname: "",
        company: "",
        title: "",
        email: "",
        telephone: "",
        message: "",
      },
    };
  },
  mounted(){
    this.scrollToTop();
  },
  //   computed: {
  //   // validateFormUpdateDepartement() {
  //   //   return !this.errors.any() && this.formUpdated.departementId != "";
  //   // }
  //     validations() {
  //   return {
  //     approbation_form: {
  //       lastname: { required, minLength: minLength(2)},
  //       firstname: { required, minLength: minLength(2) },
  //       company: { required, minLength: minLength(2) },
  //       title: { required, minLength: minLength(2) },
  //       email: { required, email },
  //       telephone: { required, minLength: minLength(8) },
  //       message: { required },
  //     },
  //   };
  // },
  // },
  validations() {
    return {
      approbation_form: {
        lastname: { required, $autoDirty: true, minLength: minLength(2) },
        firstname: { required, $autoDirty: true, minLength: minLength(2) },
        company: { required, $autoDirty: true, minLength: minLength(2) },
        title: { required, $autoDirty: true, minLength: minLength(2) },
        email: { required, $autoDirty: true, email },
        telephone: { required, $autoDirty: true, minLength: minLength(8) },
      },
    };
  },
  methods: {
    scrollToTop() {
    window.scrollTo(0,0);
  },
    submit() {
      this.v$.approbation_form.$touch();
      if (this.v$.approbation_form.$error) {
        this.$swal("Merci de bien vouloir renseigner tous les champs!!!");
      } else {
        try {
          // axios
          //   .post(
          //     "https://backend-vcard.askgs-benin.com/api/v1/user-approbation-request/register",
          //     { ...this.approbation_form }
          //   )
          //   .then((response) => {
          //     console.log(response.data);
          //   });

          axios
            .post(
              "https://backend-vcard.askgs-benin.com/api/v1/user-approbation-request/register",
              { ...this.approbation_form }
            )
            .then(() => {
              // console.log(response.data);
              this.$swal(
                `Merci ${this.approbation_form.lastname} ${this.approbation_form.firstname} d'avoir marqué votre accord! vous trouverez plus de détail dans votre boîte mail. Contactez-nous à l'adresse suivante si vous avez des préoccupations: administration@bj.askgs.africa`
              );
            })
            .catch(() => {
              this.$swal(
                "Oppps une erreur est survenue lors du traitement de vos informations. Si le problème persiste, contactez le service support: support-it@bj.askgs.africa"
              );
            });

          this.approbation_form = {
            lastname: "",
            firstname: "",
            company: "",
            title: "",
            email: "",
            telephone: "",
            message: "",
          };
        } catch (error) {
          this.$swal(
            "Oppps une erreur est survenue lors du traitement de vos informations. Si le problème persiste veuillez contacter le service support: support-it@bj.askgs.africa"
          );
        }
      }
    },
    verifyMethod(response) {
      if (response) {
        this.captcha_verified = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
