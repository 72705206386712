<template>
  <div>
    <!-- header section starts -->
    <header class="header">
      <a href="#" class="logo"
        >ASK <span title="Gras Savoye Bénin">GSB</span>
      </a>
      <nav class="navbar">
        <a href="#home">Accueil</a>
        <a href="#about">A propos</a>
        <a href="#services">Produits</a>
        <!-- <a href="#projects">Produits</a> -->
        <!-- <a href="#pricing">Digitalisation</a> -->
        <a href="#contact">Contact</a>
      </nav>

      <div class="icons">
        <div id="menu-btn" class="fas fa-bars"></div>
        <div id="info-btn" class="fas fa-info-circle"></div>
        <!-- <div id="search-btn" class="fas fa-search"></div> -->
        <div id="login-btn" class="fas fa-user"></div>
      </div>

      <!-- <form action="" class="search-form">
        <input
          type="search"
          name=""
          placeholder="search here..."
          id="search-box"
        />
        <label for="search-box" class="fas fa-search"></label>
      </form> -->

      <form  class="login-form">
        <h3>Connexion</h3>
        <input type="email" placeholder="Email" class="box" />
        <input type="password" placeholder="Mot de passe" class="box" />
        <div class="flex">
          <input type="checkbox" name="" id="remember-me" />
          <label for="remember-me">Se souvenir!</label>
          <a href="#">mot de passe oublié ?</a>
        </div>
        <input type="submit" value="se connecter" class="btn" />
        <p>je n'ai pas de compte <a href="#">Créer un compte!</a></p>
      </form>
    </header>
    <div class="contact-info">
      <div class="contact-info-header">
        <div class="contact-logo">
          <img src="@/assets/images/logo_ask.png" alt="" />
        </div>
        <div id="close-contact-info" class="fas fa-times"></div>
      </div>
      <div class="info">
        <i class="fas fa-phone"></i>
        <h3>Téléphone</h3>
        <p>+229 21303130</p>
      </div>
      <div class="info">
        <i class="fas fa-envelope"></i>
        <h3>Adresse mail</h3>
        <p>administration@bj.askgs.africa</p>
      </div>
      <div class="info">
        <i class="fas fa-map-marker-alt"></i>
        <h3>Direction Générale</h3>
        <p>Quartier Djominhoutin, Boulevard Marina, Carré 4236</p>
        <!-- <p>01 BP 6901 Cotonou</p> -->
      </div>

      <div class="share">
        <a href="#" class="fab fa-facebook-f"></a>
        <!-- <a href="#" class="fab fa-twitter"></a>
        <a href="#" class="fab fa-instagram"></a> -->
        <a href="#" class="fab fa-linkedin"></a>
      </div>
    </div>
    <!-- header section ends -->
    <section class="home" id="home">
      <div class="swiper home-slider">
        <div class="swiper-wrapper">
          <section
            class="swiper-slide slide"
            :style="{
              background: `url(${require('@/assets/images/home-slide-1.jpg')}) no-repeat`,
            }"
          >
            <div class="content">
              <h3>Courtier leader du marché béninois</h3>
              <p>
                ASK Gras Savoye Bénin dans son activité de courtage ne se limite
                pas au seul rôle traditionnel d’intermédiation entre les
                compagnies d’Assurances et ses clients.
              </p>
              <a href="#about" class="btn">Lire plus</a>
            </div>
          </section>
          <section
            class="swiper-slide slide"
            :style="{
              background: `url(${require('@/assets/images/home-slide-2.jpg')}) no-repeat`,
            }"
          >
            <div class="content">
              <h3>Conseil</h3>
              <p>
                ASK Gras Savoye Bénin agit en qualité de courtier gestionnaire,
                et également parce que nous considérons devoir vous apporter un
                éclairage sur l’évolution de votre exposition au risque, nous
                serons votre conseil.
              </p>
              <a href="#about" class="btn">Lire plus</a>
            </div>
          </section>

          <!-- <section
            class="swiper-slide slide"
            :style="{
              background: `url(${require('@/assets/images/home-slide-2.jpg')}) no-repeat`,
            }"
          >
            <div class="content">
              <h3>making dream come to life</h3>
              <p>
                ASK Gras Savoye Bénin agit en qualité de courtier gestionnaire,
                et également parce que nous considérons devoir vous apporter un
                éclairage sur l’évolution de votre exposition au risque, nous
                serons votre conseil.
              </p>
              <a href="#about" class="btn">A propos</a>
            </div>
          </section>

          <section
            class="swiper-slide slide"
            :style="{
              background: `url(${require('@/assets/images/home-slide-3.jpg')}) no-repeat`,
            }"
          >
            <div class="content">
              <h3>from concept to creation</h3>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Aspernatur beatae iusto pariatur laborum magnam eos!
              </p>
              <a href="#about" class="btn">lire plus</a>
            </div>
          </section> -->
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </section>

    <!-- home section ends -->

    <!-- about section starts  -->

    <section class="about" id="about">
      <h1 class="heading">A propos</h1>

      <div class="row">
        <div class="video">
          <!-- <video
            src="@/assets/images/about-vid.mp4"
            loop
            muted
            autoplay
          ></video> -->
          <img src="@/assets/images/home-slide-2.jpg" alt="" />
        </div>

        <div class="content">
          <h3>Courtier leader du marché béninois!</h3>
          <p>
            ASK Gras Savoye Bénin dans son activité de courtage ne se limite pas
            au seul rôle traditionnel d’intermédiation entre les compagnies
            d’Assurances et ses clients.
          </p>
          <p>
            ASK Gras Savoye Bénin agit en qualité de courtier gestionnaire, et
            également parce que nous considérons devoir vous apporter un
            éclairage sur l’évolution de votre exposition au risque, nous serons
            votre conseil.
          </p>
          <a href="#services" class="btn">lire plus</a>
        </div>
      </div>

      <div class="box-container">
        <div class="box">
          <h3>+30</h3>
          <p>années d'experiences</p>
        </div>

        <div class="box">
          <h3>+320</h3>
          <p>partenaires</p>
        </div>

        <div class="box">
          <h3>+30 000</h3>
          <p>bénéficiaires</p>
        </div>

        <div class="box">
          <h3>+33</h3>
          <p>collaborateurs engagés</p>
        </div>
      </div>
    </section>

    <!-- about section ends -->

    <!-- services section starts  -->

    <section class="services" id="services">
      <h1 class="heading">nos produits</h1>

      <div class="box-container" v-if="products">
        <div class="box" v-for="(product, idx) in products" :key="idx">
          <img :src="product.icon" alt="" />
          <h3> {{product.name}} </h3>
          <p>
            {{product.description.toUpperCase()}}
          </p>
        </div>

        <!-- <div class="box">
          <img src="@/assets/images/service-2.png" alt="" />
          <h3>Assurance Voyage</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div>

        <div class="box">
          <img src="@/assets/images/service-3.png" alt="" />
          <h3>Assurance Santé</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div>

        <div class="box">
          <img src="@/assets/images/service-4.png" alt="" />
          <h3>Assurance Globale Dommages</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div>

        <div class="box">
          <img src="@/assets/images/service-5.png" alt="" />
          <h3>Multirisque Habitation</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div> -->

        <!-- <div class="box">
          <img src="@/assets/images/service-6.png" alt="" />
          <h3>Multirisque Professionnelle</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div> -->

        <!-- <div class="box">
          <img src="@/assets/images/service-7.png" alt="" />
          <h3>building maintenance</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div>

        <div class="box">
          <img src="@/assets/images/service-8.png" alt="" />
          <h3>building renovation</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
            necessitatibus.
          </p>
        </div> -->
      </div>
    </section>

    <!-- services section ends -->

    <!-- projects section starts  -->

    <!-- <section class="projects" id="projects">
      <h1 class="heading">nos produits</h1>

      <div class="box-container">
        <a href="@/assets/images/project-1.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-1.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="@/assets/images/project-2.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-2.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="@/assets/images/project-3.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-3.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="@/assets/images/project-4.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-4.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="@/assets/images/project-5.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-5.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>

        <a href="@/assets/images/project-6.jpg" class="box">
          <div class="image">
            <img src="@/assets/images/project-6.jpg" alt="" />
          </div>
          <div class="content">
            <div class="info">
              <h3>dream home</h3>
              <p>construction, design</p>
            </div>
            <i class="fas fa-plus"></i>
          </div>
        </a>
      </div>
    </section> -->

    <!-- projects section ends -->

    <!-- reviews section starts  -->

    <!-- <section class="reviews">
      <h1 class="heading">clients reviews</h1>

      <div class="swiper reviews-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-1.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-2.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-3.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-4.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-5.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              unde suscipit quis consequuntur, tempora corporis ex molestias
              dignissimos in cumque sunt ducimus voluptate inventore harum earum
              rem aperiam vel modi?
            </p>
            <div class="user">
              <img src="@/assets/images/pic-6.png" alt="" />
              <div class="info">
                <h3>john deo</h3>
                <div class="stars">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- reviews section ends -->

    <!-- pricing section starts  -->

    <!-- <section class="pricing" id="pricing">
      <h1 class="heading">solutions digitales</h1>

      <div class="box-container">
        <div class="box">
          <i class="fas fa-home"></i>
          <h3>basic plan</h3>
          <div class="price"><span>$</span>250<span>/mo</span></div>
          <div class="list">
            <p>interior design</p>
            <p>refurbishment</p>
            <p>material supply</p>
            <p>maintenance</p>
            <p>24/7 support</p>
          </div>
          <a href="#" class="btn">choose plan</a>
        </div>

        <div class="box">
          <i class="fas fa-building"></i>
          <h3>premium plan</h3>
          <div class="price"><span>$</span>650<span>/mo</span></div>
          <div class="list">
            <p>interior design</p>
            <p>refurbishment</p>
            <p>material supply</p>
            <p>maintenance</p>
            <p>24/7 support</p>
          </div>
          <a href="#" class="btn">choose plan</a>
        </div>

        <div class="box">
          <i class="fas fa-city"></i>
          <h3>ultimate plan</h3>
          <div class="price"><span>$</span>1250<span>/mo</span></div>
          <div class="list">
            <p>interior design</p>
            <p>refurbishment</p>
            <p>material supply</p>
            <p>maintenance</p>
            <p>24/7 support</p>
          </div>
          <a href="#" class="btn">choose plan</a>
        </div>
      </div>
    </section> -->

    <!-- pricing section ends -->

    <!-- contact section starts  -->

    <section class="contact" id="contact">
      <h1 class="heading">contactez-nous!</h1>

      <div class="row">
        <iframe
          class="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.330083992989!2d2.3986307141495034!3d6.351293826904878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023553a658d7427%3A0x36028a838c9bd2b1!2sASK%20GRAS%20SAVOYE%20BENIN!5e0!3m2!1sfr!2sbj!4v1664740981375!5m2!1sfr!2sbj"
          allowfullscreen=""
          loading="lazy"
        ></iframe>

        <form action="">
          <h3>Nous contacter</h3>
          <div class="contact-double-field">
            <input type="text" placeholder="Nom" class="box" />
            <input type="text" placeholder="Prénoms" class="box" />
          </div>

          <input type="email" placeholder="Email" class="box" />
          <input type="number" placeholder="Téléphone" class="box" />
          <textarea
            name=""
            placeholder="Message"
            class="box"
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <!-- <vue-recaptcha
            sitekey="6LcU804iAAAAAOgqAy3nA6XfGiEyqfSIex-Mnxhb"
            @verify="verifyMethod"
          ></vue-recaptcha> -->
          <input
            :disabled="!captcha_verified"
            type="submit"
            value="Envoyer"
            class="btn"
          />
        </form>
      </div>
    </section>

    <!-- contact section ends -->

    <!-- blogs section starts  -->

    <!-- <section class="blogs" id="blogs">
      <h1 class="heading">Ils nous font confiance</h1>

      <div class="swiper blogs-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-1.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>

          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-2.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>

          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-3.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>

          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-4.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>

          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-5.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>

          <div class="swiper-slide slide">
            <div class="image">
              <img src="@/assets/images/blog-6.jpg" alt="" />
            </div>
            <div class="content">
              <h3>blog title goes here</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Assumenda, nobis!
              </p>
              <a href="#" class="btn">read more</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- blogs section ends -->

    <!-- client logo section starts -->

    <section class="blogs">
      <h1 class="heading">Ils nous font confiance</h1>

      <div class="logo-container">
        <div class="swiper logo-slider">
          <div class="swiper-wrapper">
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-1.png" alt="" />
            </div>
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-2.png" alt="" />
            </div>
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-3.png" alt="" />
            </div>
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-4.png" alt="" />
            </div>
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-5.png" alt="" />
            </div>
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-6.png" alt="" />
            </div>
            <!-- <div class="swiper-slide slide">
            <img src="@/assets/images/clients/client-logo-7.png" alt="" />
          </div> -->
            <div class="swiper-slide slide">
              <img src="@/assets/images/clients/client-logo-8.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- client logo section ends -->

    <!-- footer section starts  -->

    <section class="footer">
      <div class="links">
        <a class="btn" href="#home">Accueil</a>
        <a class="btn" href="#about">A propos</a>
        <a class="btn" href="#services">Produits</a>
        <!-- <a class="btn" href="#projects">projects</a>
        <a class="btn" href="#pricing">pricing</a> -->
        <a class="btn" href="#contact">Contact</a>
        <!-- <a class="btn" href="#blogs">blogs</a> -->
      </div>

      <div class="credit">
        ASK <span> Gras Savoye Bénin,  </span> 
        <!-- | tous droits reservés! | -->
        <!-- <a href="#">Mentions Légales</a> | -->
        <!-- <a href="#">politiques de confidentialité</a> -->
        <router-link to="/politiques"
          >politiques de confidentialité</router-link
        >
      </div>
    </section>



    <!-- <v-scroll-to-top></v-scroll-to-top> -->

    <!-- footer section ends -->
  </div>
</template>
<script>
// import { VueRecaptcha } from "vue-recaptcha";
import axios from 'axios'

export default {
  // components: { VueRecaptcha },
  data() {
    return {
      captcha_verified: false,
      products:[]
    };
  },
  mounted() {
    axios.get('https://iard-backend.askgs-benin.com/api/v1/product?name=').then((response) => {
        //  console.log(response.data.data.docs)
        this.products = response.data.data.docs
      })
  },
  methods: {
    verifyMethod(response) {
      if (response) {
        this.captcha_verified = true;
      }
    },
  },
};
</script>
