// import { createApp } from "vue";
import * as Vue from 'vue'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
 
import VScrollToTop from 'v-scroll-to-top';
// Styles: SCSS
import "./assets/styles/main.scss";

import EasySlider from 'vue-easy-slider';


import axios from 'axios'
import VueAxios from 'vue-axios'

const app = Vue.createApp(App)
app.use(VueAxios, axios)

// import Vuelidate from "vuelidate";
// app.use(Vuelidate)
// import Vuelidate from "vuelidate";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#863591',
    cancelButtonColor: '#f90707',
  };
app.use(VueSweetalert2, options);

app.use(store)
app.use(VScrollToTop)
app.use(EasySlider)
app.use(router)
app.component("about", () =>
import("./views/About.vue")
)
app.component("footer-app", () =>
import("./container/Footer.vue")
)
app.mount("#app");
