<template>
  <div>
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <Nav /> -->
    <router-view />
                
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
// import Nav from "./container/Nav.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
    // Nav,
   
  },
  data() {
    return {
      open_chat: false,
      attendants: [
        {
          app: "whatsapp",
          label: "Courtage en assurance",
          name: "ASK GRAS SAVOYE Bénin",
          number: "97298195",
          avatar: {
            src: require("@/assets/images/logo_ask.png"),
            alt: "ASK GS avatar",
          },
        },
        {
          app: "messenger",
          label: "Courtage en assurance",
          name: "ASK GRAS SAVOYE Bénin",
          id: "+22997298195",
          avatar: {
            src: require("@/assets/images/logo_ask.png"),
            alt: "ASK GS avatar",
          },
        },
        // ...
      ],
    };
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
